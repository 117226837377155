.widget {
  position: relative;
  display: block;

  > .widget-title {
    font-family: $mainFnt;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: normal;
    position: relative;
    margin-bottom: 60px;
    color: $mainClr;
    &:after {
      content: "";
      position: absolute;
      width: 30px;
      border-bottom: 2px solid $mainClr;
      margin-top: 10px;
      display: block;
    }
  }

  /* Widget: Global */
  &.widget_nav_menu,
  &.widget_recent_entries,
  &.widget_recent_comments,
  &.widget_categories,
  &.widget_archive {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      * + li {
        margin-top: 12px;
        padding-top: 12px;
        border-top: 1px solid $light;
      }
    }
  }

  /* Widget: Search */
  &.widget_search {
    .search-field {
      font-size: 1rem;
      height: 50px;
      margin-bottom: 10px;
    }
    .search-submit {
      width: 100%;
    }
  }

  /* Widget: Nav Menu */
  &.widget_nav_menu {
    ul {
      li {
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transform: translateX(-20px);
          @include transitions(400);
          &:before {
            content: "\e91f";
            width: 20px;
            font-family: "Pixeicons", sans-serif;
            font-size: 14px;
            opacity: 0;
            @include transitions(400);
          }
        }
        &.uk-active a,
        &.current a,
        a:hover {
          color: $mainClr;
          padding-left: 20px;
          &:before {
            transform: translateX(0);
            opacity: 1;
            color: $mainClr;
          }
        }
      }
    }
  }

  /* Widget: Recent Posts */
  &.widget_recent_entries {
    ul {
      li {
        display: flex;
        flex-direction: column;
        a {
          line-height: 1.2;
        }
        .post-date {
          font-size: 13px;
          margin-top: 5px;
        }
      }
    }
  }

  /* Widget: Tag Cloud */
  &.widget_tag_cloud {
    a {
      display: inline-block;
      font-size: 14px;
      background-color: $light;
      padding: 5px 10px;
      margin-bottom: 5px;
    }
  }
}

* + .widget {
  margin-top: 60px;
}
