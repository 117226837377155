// Color Palette
$mainClr: #6ad82f;
$mainGreen: #6aee22;
$secClr: #1e59ac;
$black: #233d87;
$navy: #162557;
$graydark: #747474;
$gray: #acacac;
$graylight: #e0e0e0;
$light: #f3f3f3;
$white: #ffffff;
$alternate: #dbe2e2;
$greenlight: #eefbe7;
$greenlight2: #b3e597;

// Font Types
$mainFnt: 'Trade Gothic', 'Roboto Condensed', sans-serif;
$secFnt: 'Avant Grade', 'Poppins', sans-serif;

// Used Colors
$bodyClr: $graydark;
$headingClr: $secClr;

// Footer
$footerTxt: #b9daf8;
$footerLnk: $white;
$footerBg: #1e59ac;
$footerCtrBg: #233d87;
$footerBtmBg: #233d87;
